import { mapProducts, mapImpressions, mapUserProperties } from "helpers/elevar-mapper";
import getCurrency from "helpers/get-currency";
import { PRODUCTION_ENV } from "utils/constants";

const trackEvent = async (event, data) => {
	window.dataLayer = window.dataLayer ?? [];
	window.dataLayer.push({
		event,
		event_id: `event_${crypto.randomUUID()}`,
		...data,
	});
};

const pushDataToElevar = (event, userProperties, eventData = {}, delay) => {
	const pushEvent = () => {
		window.ElevarDataLayer = window.ElevarDataLayer || [];
		window.ElevarDataLayer.push({
			event,
			user_properties: mapUserProperties(userProperties),
			...eventData
		});
	};
	if (delay)
		setTimeout(() => {
			pushEvent();
		}, 2000);
	else
		pushEvent();
};

const trackEcomEvent = (eventName, userProperties, ecommerceData, extraData = {}, delay = true) => {
	pushDataToElevar(eventName, userProperties, {
		...extraData,
		ecommerce: {
			currencyCode: getCurrency().code,
			...ecommerceData
		}
	}, delay);
};

export const trackUserData = (customer, cart) => {
	trackEcomEvent("dl_user_data", customer,
		{ cart_contents: { products: mapProducts(cart.items, "/collections/all") } },
		{ cart_total: cart.totalPrice?.toString() || "0.0" }, false
	);
	if (customer.isAuthenticated && window.klaviyo && typeof window.klaviyo?.identify === "function" && PRODUCTION_ENV) {
		window.klaviyo.identify({ email: customer.email });
	}
};

export const trackViewItemList = (products, collection, customer) => {
	trackEcomEvent("dl_view_item_list", customer, {
		title: collection.title,
		impressions: mapImpressions(products, collection.handle),
	});
};

export const trackSelectItem = (product, customer, source) => {
	trackEcomEvent("dl_select_item", customer, {
		click: {
			actionField: {
				list: location.pathname,
				action: "click"
			},
			products: mapProducts([].concat(product), location.pathname)
		}
	}, { source });
};

export const trackViewItem = (product, customer, list = "/collections/all") => {
	trackEcomEvent("dl_view_item", customer, {
		detail: {
			actionField: {
				list,
				action: "detail"
			},
			products: mapProducts([].concat(product), list)
		}
	});
};

export const trackAddToCart = (product, customer) => {
	trackEcomEvent("dl_add_to_cart", customer, {
		add: {
			actionField: {
				list: location.pathname
			},
			products: mapProducts([product], location.pathname)
		}
	});
	if (typeof window.TriplePixel === "function")
		window.TriplePixel("AddToCart", { item: product.variant.id, q: product.quantity || 1 });
};

export const trackRemoveFromCart = (products, customer) => {
	trackEcomEvent("dl_remove_from_cart", customer, {
		remove: {
			actionField: {
				list: location.pathname
			},
			products: mapProducts([].concat(products), "Shopping Cart")
		}
	});
};

export const trackViewCart = (cart, customer) => {
	trackEcomEvent("dl_view_cart", customer, {
		actionField: { list: "Shopping Cart" },
		impressions: mapImpressions(cart.items)
	}, { cart_total: cart.totalPrice?.toString() || "0.0" });
};

export const trackSearchTerm = (search_term, active_tab, location) => trackEvent("search", { search_term, active_tab, location });

export const trackViewSearchResults = (results, search_term, customer, view_result_type = "page", active_tab) => {
	trackEcomEvent("dl_view_search_results", customer, {
		actionField: { list: "search results" },
		impressions: mapImpressions(results.products, "search results"),
	}, { blogs: results.blogs, search_term, view_result_type, active_tab });
};

export const trackLogin = (customer) => {
	pushDataToElevar("dl_login", customer, {}, true);
};

export const trackSignup = (customer) => {
	pushDataToElevar("dl_sign_up", customer, {}, true);
};

export const trackPLPFilter = (handle, group, value, is_selected) => {
	trackEvent("plp_filter_clicked", { handle, group, value, is_selected });
};

export const trackb2bRegistration = () => trackEvent("b2b_form_submitted");

export const trackCatalogSignup = () => trackEvent("catalog_signup");

export const trackAddToCartWarranty = (product) => trackEvent("add_to_cart_warranty", { product });

export const trackClickHamburgerMenu = (state) => trackEvent("click_hamburger_menu", { state });

export const trackClickMobileMenuItem = (title) => trackEvent("click_mobile_menu_item", { title });

export const trackNavClick = (title) => trackEvent("click_desktop_nav_link", { title });

export const trackSearchActiveTab = (active_tab) => trackEvent("click_search_tab", { active_tab });

export const trackSearchEnterPress = (value) => trackEvent("search_enter_press", { value });

export const trackCartIconClick = () => trackEvent("click_cart_icon");

export const trackPDPCollectionClick = (product_title, collection_title) =>
	trackEvent("click_pdp_collection_link", { product_title, collection_title });
