import { mapCart, getOrCreateCartId, getOrCreateCart } from "utils/cart";
import { fetchRecommendedProducts } from "services/api/rebuy";
import getCurrency from "helpers/get-currency";

const updateCart = async (set, get, cart) => {
	if (!cart?.id) return;

	const shippingProtection = cart.lines.nodes.find(item => item.variant.product.title === "Shipping Protection");

	if (shippingProtection) {
		return removeCartItem(set, get, shippingProtection.id);
	}

	const updatedCart = mapCart(cart, set);

	set(state => ({ cart: { ...state.cart, ...updatedCart, isUpdating: false, isLoading: false } }), false, "FETCH_CART");
	fetchRecommendedProducts(updatedCart, get().customer?.id).then(recommendations => {
		set(state => { state.cart.recommendations = recommendations || []; }, false, "FETCH_RECOMMENDATIONS");
	});

	return updatedCart;
};

export const addCartItem = async (set, get, variantId, quantity) => {
	try {
		const shopifyClient = get().shopifyClient;
		const cartId = await getOrCreateCartId(shopifyClient);
		const lineItems = [{ merchandiseId: variantId, quantity: quantity || 1 }];

		const updatedCart = await shopifyClient.cart.addLineItems(cartId, lineItems);
		return updateCart(set, get, updatedCart);
	} catch (err) {
		console.log({ err });
	}
};

export const updateCartItem = async (set, get, id, quantity) => {
	try {
		set(state => { state.cart.isUpdating = true; }, false, "UPDATING_CART");
		const shopifyClient = get().shopifyClient;
		const cartId = await getOrCreateCartId(shopifyClient);
		const lineItems = [{ id, quantity }];

		const updatedCart = await shopifyClient.cart.updateLineItems(cartId, lineItems);
		return updateCart(set, get, updatedCart);
	} catch (err) {
		console.log({ err });
	}
};

export const removeCartItem = async (set, get, id) => {
	try {
		const shopifyClient = get().shopifyClient;
		const cartId = await getOrCreateCartId(shopifyClient);
		const lineIds = [id];

		const updatedCart = await shopifyClient.cart.removeLineItems(cartId, lineIds);
		return updateCart(set, get, updatedCart);
	} catch (err) {
		console.log({ err });
	}
};

export const fetchCart = async (set, get) => {
	try {
		const shopifyClient = get().shopifyClient;
		let cart = await getOrCreateCart(shopifyClient);

		const { countryCode: customerCountryCode, code: customerCurrencyCode } = getCurrency();
		const currentCountryCode = cart.buyerIdentity?.countryCode;
		const cartCurrencyCode = cart.cost.subtotalAmount?.currencyCode;

		if (customerCountryCode !== currentCountryCode ||
			customerCurrencyCode !== cartCurrencyCode) {
			const response = await shopifyClient.cart.buyerIdentityUpdate(cart.id);
			if (response?.cart)
				cart = response.cart;
		}

		return updateCart(set, get, cart);

	}
	catch (err) {
		console.log("fetchCart", err);
	}
};

export const updateDiscountCodes = async (set, get, discountCodes) => {
	try {
		const shopifyClient = get().shopifyClient;
		const cartId = await getOrCreateCartId(shopifyClient);

		const updatedCart = await shopifyClient.cart.updateDiscountCodes(cartId, discountCodes);
		return updateCart(set, get, updatedCart);
	} catch (err) {
		console.log({ err });
	}
};
