import { recentlyViewedKey } from "utils/constants";
import { fetchProductsByIds } from "services/api/rebuy";
import { mapRebuyProduct } from "helpers/rebuy";
import getCurrency from "helpers/get-currency";

const initialState = {
	isMenuOpen: false,
	isSearchOpen: false,
	isCartOpen: false,
	customerCountry: "",
	currencyCode: "",
	productSettings: {
		/*
		[id]: {
			isCylindoFirstSlide: false,
			activeTab: 0,
			...otherSettings
		}
		 */
	},
	pageLoading: true,
	recentlyViewed: [],
	isClient: false,
};

const settingsSlice = (set, get) => ({
	...initialState,

	setIsClient: () => {
		const { isClient } = get().settings;
		if (isClient) return;
		set(state => {
			state.settings.isClient = true;
		}, false, "SET_IS_CLIENT");
	},

	togglePageLoading: () => {
		set(state => {
			state.settings.pageLoading = false;
		}, false, "UPDATE_PAGE_LOADING");
	},

	updateCustomerLocation: () => {
		const { countryCode: customerCountryCode, code: customerCurrencyCode } = getCurrency();
		set(state => {
			state.settings.currencyCode = customerCurrencyCode;
			state.settings.customerCountry = customerCountryCode;
		}, false, "UPDATE_CUSTOMER_LOCALE");
	},

	updateProductSettings: (productId, settings) => {
		set(state => {
			state.settings.productSettings = {
				...state.settings.productSettings,
				[productId]: {
					...state.settings.productSettings?.[productId],
					...settings,
				},
			};
		}, false, "UPDATE_PRODUCT_SETTINGS");
	},

	updateCylindoState: (productId, visibility) => {
		get().settings.updateProductSettings(productId, { isCylindoFirstSlide: visibility });
	},

	updateActiveTab: (productId, activeTab) => {
		get().settings.updateProductSettings(productId, { activeTab });
	},

	updateRecentlyViewed: async (product = null) => {
		const recentIds = JSON.parse(localStorage.getItem(recentlyViewedKey)) || [];

		// Check if local storage is not empty and state has no items
		if (recentIds.length > 0 && get().settings.recentlyViewed.length === 0) {
			try {
				const products = await fetchProductsByIds(recentIds);

				// Sort products to match the order in recently viewed titles
				const sortedProducts = recentIds
					.map(id => {
						const product = products?.find(product => +product.id === +id);
						return product ? mapRebuyProduct(product) : false;
					})
					.filter(Boolean);

				set(state => {
					state.settings.recentlyViewed = sortedProducts;
				}, false, "FETCH_RECENTLY_VIEWED");

			} catch (error) {
				console.error("Error fetching products:", error);
			}
		}

		set(state => {
			const recentlyViewed = get().settings.recentlyViewed;

			const uniqueProducts = Array.from(
				new Map([product, ...recentlyViewed].map(item => [+item.id, item])).values()
			).slice(0, 10);

			state.settings.recentlyViewed = uniqueProducts;
			localStorage.setItem(recentlyViewedKey, JSON.stringify(uniqueProducts.map(p => +p.id)));
		}, false, "UPDATE_RECENTLY_VIEWED");

	},

	toggleMenu: (value) => {
		set(state => {
			state.settings.isMenuOpen = typeof value === "boolean" ? value : !state.settings.isMenuOpen;
			state.settings.isSearchOpen = false;
			state.settings.isCartOpen = false;
		}, false, "TOGGLE_MENU");
	},

	toggleSearch: () => {
		set(state => {
			state.settings.isSearchOpen = !state.settings.isSearchOpen;
			state.settings.isMenuOpen = false;
			state.settings.isCartOpen = false;
		}, false, "TOGGLE_SEARCH");
	},

	toggleCart: () => {
		set(state => {
			state.settings.isCartOpen = !state.settings.isCartOpen;
			state.settings.isMenuOpen = false;
			state.settings.isSearchOpen = false;
		}, false, "TOGGLE_CART");
	},
});

export default settingsSlice;