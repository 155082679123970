export const setConvertAttrToCookie = () => {
	const attributes = localStorage.getItem("convert_attributes");
	if (attributes) {
		const expires = new Date();
		expires.setTime(expires.getTime() + (24 * 60 * 60 * 1000)); // Set expiration to 1 day from now

		// eslint-disable-next-line max-len
		document.cookie = `convert_attributes=${encodeURIComponent(attributes)}; path=/; secure; samesite=strict; expires=${expires.toUTCString()}; domain=.mod-lighting.com;`;
	}
};

export const isExperienceActive = (experienceIds) => {
	if (typeof window === "undefined" || !window.convert) return false;

	const convertSessionCookie = window.convert.getCookie("_conv_v");
	if (!convertSessionCookie) return false;

	return experienceIds.some((id) => new RegExp(id).test(convertSessionCookie));
};