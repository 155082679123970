import { slugify } from "./slugify";

const getPreviewImageUrl = (image, images) => {
	return image?.src || images?.[0]?.src || "";
};

const getMinVariant = (variants) => {
	return variants.reduce((minPriceVariant, variant) => {
		const price = +variant.price;
		if (price < minPriceVariant.price) {
			return { variant, price };
		}
		return minPriceVariant;
	}, { price: Infinity }).variant;
};

const getVariantMarkets = (presentmentPrices) => {
	return presentmentPrices?.reduce((acc, { price, compare_at_price }) => {
		const currencyCode = price.currency_code?.toLowerCase()?.substring(0, 2);
		acc[currencyCode === "eu" ? "de" : currencyCode] = {
			currency: price?.currency_code,
			price: +price?.amount,
			compareAtPrice: +compare_at_price?.amount,
		};
		return acc;
	}, {});
};

const isVariantAvailable = variant =>
	variant.inventory_policy.toUpperCase() === "CONTINUE" ||
	variant.inventory_quantity > 0;

const mapVariant = (variant) => ({
	...variant,
	id: variant.id,
	title: variant.title,
	gid: variant.admin_graphql_api_id,
	handle: slugify(variant.title),
	isAvailable: isVariantAvailable(variant),
	markets: getVariantMarkets(variant.presentment_prices),
	compareAtPrice: +variant.compare_at_price,
	price: +variant.price,
});

export const mapRebuyProduct = (product) => {
	const { handle, image, variants, images, ...rest } = product;

	const previewImageUrl = getPreviewImageUrl(image, images);
	const minVariant = getMinVariant(variants);

	return {
		handle,
		previewImageUrl,
		minVariantPrice: +minVariant.price,
		variant: mapVariant(minVariant),
		variants: variants.map(mapVariant),
		...rest,
	};
};
