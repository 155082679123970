import { setUrlParam, getUrlParam } from "helpers/url-params";
import { mapFilterTags } from "utils/filter-sort";
import { sortOptions } from "utils/constants";
import { trackPLPFilter } from "analytics";
import { isExperienceActive } from "helpers/convert";

const initialState = {
	collections: {},
	/* collections: { 
		handle: { tags: { group: { tag1: false, tag2: false, ... } }, sortBy: "created-descending",
			 totalActiveFilters: 0, priceRange: { min: 0, max: 0 },
			 pageDetails: { showing: { start: 0, end: 0 }, totalResults: 0 } 
	} */
	isSlidePaneOpen: false,
	visible: isExperienceActive(["v.1004310164-g"]) ? false : true,
};

const filtersSlice = (set) => ({
	// Properties
	...initialState,

	// Actions
	populateFilters: (handle, filters, priceRange, defaultSort) => {
		const sortByParam = getUrlParam("sort_by");
		let sortBy = defaultSort || "best-selling";
		if (sortByParam && sortByParam !== sortBy && sortByParam in sortOptions) sortBy = sortByParam;

		const priceRangeParam = getUrlParam("price_range");
		if (priceRangeParam) {
			const [min, max] = priceRangeParam.split("_");
			if (min >= priceRange.min && max <= priceRange.max)
				priceRange = { min: +min, max: +max };
		}
		set(state => {
			const currentState = state.filters.collections[handle];
			state.filters.collections[handle] = {
				tags: mapFilterTags(filters),
				priceRange: currentState?.priceRange || priceRange,
				sortBy,
				...currentState
			};
			state.filters.visible = !isExperienceActive(["v.1004310164-g"]);
		}, false, "POPULATE_FILTERS");
	},

	setPriceRange: (handle, priceRange) => {
		set(state => {
			trackPLPFilter(handle, "price", priceRange);
			state.filters.collections[handle].priceRange = priceRange;
			setUrlParam("price_range", `${priceRange.min}_${priceRange.max}`);
		}, false, "SET_PRICE_RANGE");
	},

	setTotalActiveFilters: (handle, totalActiveFilters) => {
		set(state => {
			state.filters.collections[handle].totalActiveFilters = totalActiveFilters;
		}, false, "SET_TOTAL_ACTIVE_FILTERS");
	},

	toggleSlidePane: () => {
		set((state) => {
			state.filters.isSlidePaneOpen = !state.filters.isSlidePaneOpen;
		}, false, "TOGGLE_SLIDE_PANE");
	},

	changeSortBy: (handle, sortBy) => {
		set(state => {
			state.filters.collections[handle].sortBy = sortBy;
			setUrlParam("sort_by", sortBy);
		}, false, "CHANGE_SORT_BY");
	},

	toggleFilter: (handle, group, tag) => {
		set(state => {
			const currentTagState = state.filters.collections[handle].tags[group][tag];
			trackPLPFilter(handle, group, tag, !currentTagState);
			state.filters.collections[handle].tags[group][tag] = !currentTagState;
		}, false, "TOGGLE_FILTER");
	},

	clearAllFilters: (handle) => {
		set(state => {
			const tags = state.filters.collections[handle].tags;
			for (const category in tags) {
				for (const tag in tags[category]) {
					tags[category][tag] = false;
				}
			}
			state.filters.collections[handle].tags = tags;
		}, false, "CLEAR_ALL_FILTERS");
	},

	updatePageDetails: (handle, currentPage, pageSize, totalResults, activePromoCard) => {
		set(state => {
			const collection = state.filters.collections[handle] || {};
			const start = (currentPage - 1) * pageSize + (activePromoCard && currentPage === 1 ? 1 : 0) + (!activePromoCard ? 1 : 0);
			const end = currentPage === 1 && activePromoCard
				? Math.min(11, totalResults)
				: Math.min(currentPage * pageSize - (activePromoCard && currentPage > 1 ? 1 : 0), totalResults);
			const showing = { start, end };

			collection.pageDetails = { showing, totalResults };
			state.filters.collections[handle] = collection;
		}, false, "UPDATE_PAGINATION_SUMMARY");
	},

	toggleVisibility: () => {
		set(state => {
			state.filters.visible = !state.filters.visible;
		}, false, "TOGGLE_VISIBILITY");
	},

});

export default filtersSlice;
