/* eslint-disable max-lines */
import {
	CustomerUserErrorFragment, CustomerAddressFragment, PageInfoFragment,
	CustomerAccessTokenFragment, DiscountApplicationFragment, CartFragment,
	CartUserErrorFragment
} from "./fragments";

export const cartQuery = (country) => `
${CartFragment}
query ($id: ID!) @inContext(country: ${country})  {
	cart(id: $id) {
			...CartFragment
	}
}
`;

export const customerAccessTokenMutation = `
	${CustomerUserErrorFragment}
	${CustomerAccessTokenFragment}
	mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
		customerAccessTokenCreate(input: $input) {
			customerAccessToken {
				...CustomerAccessTokenFragment
			}
			customerUserErrors {
				...CustomerUserErrorFragment
			}
		}
	}
`;

export const customerCreateMutation = `
	${CustomerUserErrorFragment}
	${CustomerAddressFragment}
	mutation customerCreate($input: CustomerCreateInput!) {
		customerCreate(input: $input) {
			customer {
				displayName
				firstName
				email
				id
				lastName
				createdAt
				numberOfOrders
				tags
				defaultAddress {
					...CustomerAddressFragment
				}
			}
			customerUserErrors {
				...CustomerUserErrorFragment
			}
		}
	}
`;

export const customerUpdateMutation = `
	${CustomerAccessTokenFragment}
	${CustomerUserErrorFragment}
  mutation customerUpdate($customerAccessToken: String!, $input: CustomerUpdateInput!) {
    customerUpdate(customerAccessToken: $customerAccessToken, customer: $input) {
      customerAccessToken {
        ...CustomerAccessTokenFragment
      }
      customerUserErrors {
        ...CustomerUserErrorFragment
      }
    }
  }
`;

export const getCustomerQuery = (token) => `
	${CustomerAddressFragment}
	{
		customer(customerAccessToken: "${token}") {
			acceptsMarketing
			displayName
			firstName
			email
			id
			lastName
			createdAt
			numberOfOrders
			tags
			defaultAddress {
				...CustomerAddressFragment
			}
		}
	}
`;

export const personalAddressesQuery = (token, afterCursorEl) => `
	${CustomerAddressFragment}
	${PageInfoFragment}
	{
		customer(customerAccessToken: "${token}") {
			addresses(first: 5, ${afterCursorEl}, reverse: true) {
				pageInfo {
					...PageInfoFragment
				}
				nodes {
					...CustomerAddressFragment
				}
			}
			defaultAddress {
				...CustomerAddressFragment
			}
			acceptsMarketing
			displayName
			email
			firstName
			id
			lastName
			phone
			updatedAt
		}
	}
`;

export const customerAddressUpdateMutation = `
	${CustomerAddressFragment}
	${CustomerUserErrorFragment}
	mutation customerAddressUpdate($customerAccessToken: String!, $id: ID!, $address: MailingAddressInput!) {
		customerAddressUpdate(
			customerAccessToken: $customerAccessToken
			id: $id
			address: $address
		) {
			customerAddress {
				...CustomerAddressFragment
			}
			customerUserErrors {
				...CustomerUserErrorFragment
			}
		}
	}
`;

export const customerAddressCreateMutation = `
	${CustomerAddressFragment}
	${CustomerUserErrorFragment}
	mutation customerAddressUpdate($customerAccessToken: String!, $address: MailingAddressInput!) {
		customerAddressCreate(
			customerAccessToken: $customerAccessToken
			address: $address
		) {
			customerAddress {
				...CustomerAddressFragment
			}
			customerUserErrors {
				...CustomerUserErrorFragment
			}
		}
	}
`;

export const customerAddressDeleteMutation = `
	mutation customerAddressDelete($customerAccessToken: String!, $id: ID!) {
		customerAddressDelete(customerAccessToken: $customerAccessToken, id: $id) {
			customerUserErrors {
				field
			}
			deletedCustomerAddressId
		}
	}
`;

export const changeDefaultAddressMutation = `
	${CustomerAddressFragment}
	mutation customerDefaultAddressUpdate($addressId: ID!, $customerAccessToken: String!) {
		customerDefaultAddressUpdate(addressId: $addressId, customerAccessToken: $customerAccessToken) {
			customer {
				id
				defaultAddress {
					...CustomerAddressFragment
				}
			}
			customerUserErrors {
				field
			}
		}
	}
`;

export const ordersQuery = (token, afterCursorEl) => `
	${PageInfoFragment}
	${DiscountApplicationFragment}
	{
		customer(customerAccessToken: "${token}") {
			orders(first: 5, ${afterCursorEl}, sortKey: PROCESSED_AT, reverse: true) {
				pageInfo {
					...PageInfoFragment
				}
				nodes {
					id
					name
					statusUrl
					orderNumber
					fulfillmentStatus
					customerUrl
					financialStatus
					processedAt
					cancelReason
					canceledAt
					subtotalPrice {
						amount
						currencyCode
					}
					discountApplications(first: 10) {
						pageInfo {
							hasNextPage
							hasPreviousPage
						}
						edges {
							node {
								...DiscountApplicationFragment
							}
						}
					}
					billingAddress {
						formatted
						name
					}
					shippingAddress {
						formatted
						name
					}
					lineItems(first: 50) {
						nodes {
							title
							quantity
							variant {
								id
								title
								sku
								compareAtPrice {
									amount
									currencyCode
								}
								price {
									amount
									currencyCode
								}
								product {
									id
									title
									handle
								}
								priceV2 {
									amount
									currencyCode
								}
							}
							discountedTotalPrice {
								amount
								currencyCode
							}
						}
					}
					totalPrice {
						amount
						currencyCode
					}
					totalShippingPrice {
						amount
						currencyCode
					}
					totalTax {
						amount
						currencyCode
					}
				}
			}
		}
	}
`;

export const customerRecoverMutation = `
	${CustomerUserErrorFragment}
	mutation customerRecover($email: String!) {
		customerRecover(email: $email) {
			customerUserErrors {
				...CustomerUserErrorFragment
			}
		}
	}
`;

export const customerResetByUrlMutation = `
	${CustomerUserErrorFragment}
	${CustomerAccessTokenFragment}
	mutation customerResetByUrl($resetUrl: URL!, $password: String!) {
		customerResetByUrl(resetUrl: $resetUrl, password: $password) {
			customerAccessToken {
				...CustomerAccessTokenFragment
			}
			customerUserErrors {
				...CustomerUserErrorFragment
			}
		}
	}
`;

export const activateCustomerByUrlMutation = `
	${CustomerUserErrorFragment}
	${CustomerAccessTokenFragment}
	mutation customerActivateByUrl($activationUrl: URL!, $password: String!) {
		customerActivateByUrl(activationUrl: $activationUrl, password: $password) {
			customerAccessToken {
				...CustomerAccessTokenFragment
			}
			customerUserErrors {
				...CustomerUserErrorFragment
			}
		}
	}
`;

export const cartCreateMutation = (country) => `
	${CartFragment}
	${CartUserErrorFragment}
	mutation cartCreate($cartInput: CartInput!) @inContext(country: ${country})  {
		cartCreate(input: $cartInput) {
			cart {
				...CartFragment
			}
			userErrors {
				...CartUserErrorFragment
			}
		}
	}`
	;

export const cartLinesAddMutation = (country) => `
	${CartFragment}
	${CartUserErrorFragment}
	mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) @inContext(country: ${country}) {
		cartLinesAdd(cartId: $cartId, lines: $lines) {
			cart {
				...CartFragment
			}
			userErrors {
				...CartUserErrorFragment
			}
		}
	}
	`;

export const cartLinesUpdateMutation = (country) => `
	${CartFragment}
	${CartUserErrorFragment}
	mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) @inContext(country: ${country}) {
		cartLinesUpdate(cartId: $cartId, lines: $lines) {
			cart {
				...CartFragment
			}
			userErrors {
				...CartUserErrorFragment
			}
		}
	}
	`;

export const cartLinesRemoveMutation = (country) => `
	${CartFragment}
	${CartUserErrorFragment}
	mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) @inContext(country: ${country}) {
		cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
			cart {
				...CartFragment
			}
			userErrors {
				...CartUserErrorFragment
			}
		}
	}
	`;

export const cartBuyerIdentityUpdateMutation = (country) => `
	${CartFragment}
	${CartUserErrorFragment}
	mutation cartBuyerIdentityUpdate($buyerIdentity: CartBuyerIdentityInput!, $cartId: ID!) @inContext(country: ${country}) {
		cartBuyerIdentityUpdate(buyerIdentity: $buyerIdentity, cartId: $cartId) {
			cart {
				...CartFragment
			}
			userErrors {
				...CartUserErrorFragment
			}
		}
	}
	`;

export const cartDiscountCodesUpdateMutation = (country) => `
	${CartFragment}
	${CartUserErrorFragment}
	mutation cartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!]!) @inContext(country: ${country}) {
		cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
			cart {
				...CartFragment
			}
			userErrors {
				...CartUserErrorFragment
			}
		}
	}
	`;