import { CART_ID } from "utils/constants";
import { extractIdFromGid } from "helpers/extract-gid";
import { slugify } from "helpers/slugify";

const getExistingCartId = () => localStorage.getItem(CART_ID);

export const getOrCreateCartId = async (shopifyClient) => {
	const cartId = getExistingCartId();
	if (cartId) return cartId;

	const cart = await shopifyClient.cart.create();

	localStorage.setItem(CART_ID, cart.id);

	return cart.id;
};

export const getOrCreateCart = async (shopifyClient) => {
	const cartId = getExistingCartId();
	let cart;

	if (cartId)
		cart = await shopifyClient.cart.fetch(cartId);

	if (!cart?.id) {
		localStorage.removeItem(CART_ID);
		cart = await shopifyClient.cart.create();
		localStorage.setItem(CART_ID, cart.id);
	}

	return cart;
};

export const mapCart = (cart) => {
	const lineItems = cart.lines?.nodes;
	const createdAt = new Date(cart.createdAt);
	const totalPrice = cart.cost.subtotalAmount;
	const checkoutUrl = cart.checkoutUrl;
	const cartId = cart.id;
	const discountCodes = cart.discountCodes;

	const totalDiscountAmount = cart.discountAllocations?.reduce((total, allocation) => {
		return total + parseFloat(allocation.discountedAmount.amount);
	}, 0) || 0;

	let totalQuantity = 0, totalCompareAtPrice = 0, totalLineItemDiscountAmount = 0;

	const items = !lineItems?.length ? [] : lineItems.filter(item => item.variant)
		.map(item => {
			const discounts = [];
			const price = +item?.variant?.price?.amount;
			const compareAtPrice = Math.max(+item?.variant?.compareAtPrice?.amount || 0, price) ?? price;

			totalCompareAtPrice += compareAtPrice * item.quantity;
			let totalDiscountedPrice = price * item.quantity;

			const itemDiscounts = item.discountAllocations;
			totalQuantity += item?.quantity;
			let itemDiscountsAmount = 0;
			if (itemDiscounts?.length) {
				itemDiscounts?.forEach((discount) => {
					const amount = parseFloat(discount?.discountedAmount?.amount);
					discounts.push({
						title: discount?.title ?? discount?.code,
						amount,
					});
					if (amount) itemDiscountsAmount += amount;
				});
				totalDiscountedPrice -= itemDiscountsAmount;
			}

			totalLineItemDiscountAmount += itemDiscountsAmount;

			return {
				id: extractIdFromGid(item.variant.product.id),
				tempId: item.id,
				title: item.variant.product.title,
				handle: item.variant.product.handle,
				type: item.variant.product.productType,
				quantity: item.quantity,
				currency: item.variant.priceV2.currencyCode,
				image: { ...item.variant.image, altText: item.title },
				discounts,
				variants: item.variant.product.variants.nodes.map((variant) => ({
					isAvailable: variant.available,
					title: variant.title,
					handle: slugify(variant.title),
				})),
				variant: {
					isAvailable: item.variant.available,
					id: extractIdFromGid(item.variant.id),
					sku: item.variant.sku,
					title: item.variant.title,
					selectedOptions: item.variant.selectedOptions,
					price,
					compareAtPrice,
					discountedPrice: +(totalDiscountedPrice / item.quantity).toFixed(2),
					totalDiscountedPrice,
					handle: slugify(item.variant.title),
				},
			};
		});

	const cartDetails = {
		id: cartId,
		items,
		createdAt,
		totalPrice: parseFloat(totalPrice?.amount),
		totalCompareAtPrice,
		discount: parseFloat(totalPrice?.amount) - totalCompareAtPrice + totalLineItemDiscountAmount,
		totalDiscountAmount,
		totalLineItemDiscountAmount,
		checkoutUrl,
		totalQuantity,
		currencyCode: cart.cost?.subtotalAmount?.currencyCode,
		discountCodes,
		loading: false,
		error: false,
	};

	return cartDetails;
};

export const calculatePercentageDiscount = (originalPrice, salePrice) => {
	if (!originalPrice || !salePrice) return null;
	const discountPercentage = ((originalPrice - salePrice) / originalPrice) * 100;
	return Math.round(discountPercentage);
};